<template>
  <div style="position:relative;" v-show="this.contactID != ''">
    <div
      style="position:fixed;
    bottom:0px;
    right:0px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    max-height: 600px;
    z-index:10;
    border: solid 0.1px #DDFDFE;"
    >
      <div style="width:350px;">
        <b-row
          style="margin-left: 0; margin-right:0;padding-left: 10px; padding-right: 10px; justify-content: flex-end; border-bottom: solid 1px grey;"
          class="py-3"
        >
          <b-avatar
            size="32px"
            :text="getInitials(contactName)"
            :variant="getVariant(this.name)"
          ></b-avatar>
          <span
            style="font-size: small; font-weight:600; margin-right: auto; white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; max-width: 200px;"
            >{{ contactName }}</span
          >
          <span
            v-show="!this.minimiseChat"
            @click="hideChat()"
            class="material-icons mx-2 iconhover"
            title="minnimise"
            style="text-align:right; 
"
          >
            close_fullscreen</span
          ><span
            v-show="this.minimiseChat"
            @click="hideChat()"
            class="material-icons mx-2 iconhover"
            title="open"
            style="text-align:right;"
          >
            open_in_full</span
          ><span
            @click="closeChatWindow()"
            class="material-icons mx-2 iconhover"
            title="close"
            style="text-align:right;"
          >
            close
          </span>
        </b-row>
        <div :style="this.minimiseChat ? 'display: none' : 'display: block'">
          <article ref="articles" class="contentMessages" v-scroll="onScroll">
            <div>
              <div class="chat">
                <p v-for="(m, index) in messages" :key="index">
                  <messageBubble
                    v-bind:body="m.messageBody"
                    v-bind:inbound="inboundBoolean(m.direction)"
                    v-bind:dateTime="m.dateUTC"
                    sendMode="manual and system"
                    v-bind:contact="m.recipientGUID"
                    :sender="m.contactBusinessName"
                    :id="m.id"
                  ></messageBubble>
                </p>
              </div>
            </div>
          </article>
        </div>
        <div :style="this.minimiseChat ? 'display: none' : 'display: block'">
          <b-form v-on:submit.prevent="sendMessage">
            <b-button
              size="sm"
              variant="outline-primary"
              style="color: #1E91D6; border-color: #1E91D6"
              type="submit "
              class="my-1 buttonoverride"
              >send</b-button
            >
            <b-form-textarea
              id="textarea-rows"
              placeholder="Type message ...."
              rows="1"
              size="md"
              v-model="sendMessageText"
            ></b-form-textarea>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import messageBubble from "../../views/panel/conversationComponents/messageBubble.vue";
import { EventBus } from "../../eventBus";

export default {
  name: "chatWindow",
  components: { messageBubble },
  props: {
    contactID: String,
    contactName: String,
    cleanPhoneNumber: String,
    contactFirstName: String,
    organisationName: String
  },
  data() {
    return {
      minimiseChat: false,
      height: 0,
      busy: false,
      sendMessageText: ""
    };
  },
  methods: {
    getInitials(str) {
      let s = str ? str : "all";

      var matches = s.match(/\b(\w)/g);
      return matches.join("").substring(0, 3);
    },
    getVariant(str) {
      let initials = this.getInitials(str);

      let i = initials.charCodeAt(0);

      let i2 = initials.charCodeAt(1) ? initials.charCodeAt(1) : 0;

      let i3 = initials.charCodeAt(2) ? initials.charCodeAt(2) : 0;

      var options = [
        "secondary",
        "primary",
        "dark",
        "light",
        "success",
        "danger",
        "warning",
        "info"
      ];

      return options[(i + i2 + i3) % 8];
    },
    inboundBoolean(b) {
      if (b == "in" || b == "IN") {
        return true;
      } else {
        return false;
      }
    },
    hideChat() {
      this.minimiseChat = !this.minimiseChat;
    },
    closeChatWindow() {
      this.$emit("close", "");
    },
    onScroll(e, position) {
      this.position = position;
      if (position.scrollTop == 0) {
        this.height = this.$refs.articles.scrollHeight;
        this.getMessages();

        //need to position scroll bar + handle first load.
      }
    },

    sendMessage() {
      if (this.sendMessageText.length == 0) {
        return;
      }

      this.axios
        .post(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/sendMessage",
          {
            userGUID: this.$store.getters.getUserGUID,
            recipientGUID: this.contactID,
            message: this.sendMessageText,
            phoneNumber: this.cleanPhoneNumber,
            senderCompanyName: this.organisationName,
            recipientName: this.contactName
          },
          { withCredentials: true }
        )
        .then(response => {
          this.r = response;
          this.sendMessageText = "";

          //push message to array
        });
    },
    getMessages() {
      this.busy = true;

      let url =
        process.env.VUE_APP_BASE_API_URL +
        "auth/" +
        this.$store.getters.getUserGUID +
        "/messages" +
        "/contact/" +
        this.contactID;

      this.axios
        .post(
          url,
          {
            id: this.minID
          },
          {
            withCredentials: true
          }
        )
        .then(response => {
          let r = response.data;
          if (r.length > 0) {
            this.convos = response.data;

            let p = { data: r, contact: this.contactID };

            this.$store.commit("storeMessagesRecipientGrouped", { p });
          }
        })
        .then(() => {
          this.busy = false;
          this.$refs.articles.scrollTop =
            this.$refs.articles.scrollHeight - this.height;
        });
    },
    chatMessageHandler() {
      if (!this.messages) {
        this.getMessages();
      }
    },
    focusOnNewestMessage() {
      var container = this.$refs.articles;
      setTimeout(function() {
        container.scrollTop = container.scrollHeight;
      }, 2000);
    }
  },
  watch: {
    contactID() {
      this.chatMessageHandler(); //this.IDhandler();
    }
  },
  computed: {
    messages() {
      let m = this.$store.getters.getMessages[this.contactID];

      return m;
    },
    minID() {
      //need to collect this at the recipient/all levels

      if (this.contactID in this.$store.getters.getMessages) {
        let c = this.$store.getters.getMessages[this.contactID];

        if (c === "undefined" || c.length == 0) {
          return 1000000000;
        }

        let record = c.reduce(function(prev, curr) {
          return prev.id < curr.id ? prev : curr;
        });

        return record.id;
      } else {
        return 1000000000;
      }
    }
  },
  mounted() {
    this.minimiseChat = false;
    EventBus.$on("scrollToBottom", this.focusOnNewestMessage);
  },
  beforeDestroy() {
    EventBus.$off("scrollToBottom", this.focusOnNewestMessage);
  }
};
</script>

<style scoped>
.chat {
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  box-sizing: border-box;
  box-shadow: 0 0 8rem 0 rgba(black, 0.1), 0rem 2rem 4rem -3rem rgba(black, 0.5);
  overflow: auto;
}

.contentMessages {
  flex: 1 1 auto;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.iconhover {
  cursor: pointer;
}

.iconhover:hover {
  transform: scale(1.3);
}

.buttonoverride {
}

.buttonoverride:hover {
  background-color: #1e91d6 !important;
  color: #ddfdfe !important;
}
</style>
