<template>
  <div>
    <h1>Not Logged in</h1>
    <p>
      REST TO SMS & SOCKET BUTTON
      <button @click="restEventtoSMSSocket">emit</button>
    </p>
    <p>
      REST TO SOCKET BUTTON <button @click="restEventtoSocket">emit</button>
    </p>
    <p>REST BUTTON <button @click="restEvent">emit</button></p>
    <p>response from server: {{ text }}</p>
    <p>SOCKET</p>
    <input type="text" v-model="socketMSG" />
    <button @click="emitEvent">emit</button>
    <br />SOCKET STUFF
    <div style="height: 400px; overflow:scroll">
      <p v-for="(m, index) in this.$store.getters.getChat" :key="index">
        {{ m }}
      </p>
    </div>
  </div>
</template>

import axios

<script>
export default {
  name: "NotLoggedIN",
  components: {},
  props: {},
  mounted() {},
  data() {
    return {
      text: "",
      msg: [],
      socketMSG: "",
      showModal: false
    };
  },

  methods: {
    show() {
      this.$custModal.show("hello-world");
    },
    hide() {
      this.$custModal.hide("hello-world");
    },
    restEvent() {
      this.axios
        .get(process.env.VUE_APP_BASE_API_URL, { withCredentials: true })
        .then(response => (this.text = response.data));
    },
    restEventtoSocket() {
      this.axios.post(
        "http://localhost:3002",
        {
          userGUID: this.$store.getters.getUserGUID
        },
        { withCredentials: true }
      );
    },
    restEventtoSMSSocket() {
      this.axios.post(
        "http://localhost:3002/sms",
        {
          userGUID: this.$store.getters.getUserGUID
        },
        { withCredentials: true }
      );
    },
    emitEvent() {
      this.$socket.client.emit("MESSAGE", this.socketMSG);
    }
  },
  computed: {
    comments() {
      return this.$store.getters.getChat;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
