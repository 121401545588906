<template>
  <div>
    <h1 v-if="this.$store.state.auth.auth">LOGGED IN</h1>
    <h1 v-else>Oh not logged in 😢</h1>
    <br />
    <div style="max-width: 500px; text-align: center; margin: auto;">
      <b-tabs>
        <b-tab title="LOGIN" active>
          <login />
        </b-tab>
        <b-tab title="SIGN UP">
          <signup />
        </b-tab>
      </b-tabs>
    </div>
    <router-link to="/reset">PASSWORD RESET</router-link>
  </div>
</template>

<script>
import login from "./auth/login";
import signup from "./auth/signup";

export default {
  name: "Auth",
  components: { login, signup },
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
