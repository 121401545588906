<template>
  <div style="padding:30px;">
    <div style="float:left; font-size:32px; color:#616161;" class="my-3">
      <i class="fas fa-sliders-h"></i>
      SETTINGS
    </div>
    <div class="card" style="clear:left;">
      <b-form-checkbox switch size="lg" v-model="custommessageName">
        Turn on named SMS's
      </b-form-checkbox>
      <div class="card p-2" style="clear:left;">
        <div class="row">
          <div>SENDER NAME:<b-input v-model="sendername"></b-input></div>
          {{ sendername }}
        </div>
      </div>
      what is a named SMS? picture library

      <div class="card p-2">
        EXPORTS - billing, messages, conversations? modal to select parameters.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "account",
  data() {
    return {
      sendername: "",
      custommessageName: ""
    };
  },
  methods: {
    getStripeSession() {
      let url = process.env.VUE_APP_BASE_API_URL + "stripe";

      this.axios
        .post(
          url,
          {
            price: "price_1JrgpdIU6fXqzdC5cdC21ypA",
            mode: "subscription"
          },
          { withCredentials: true }
        )
        .then(response => {
          this.resp = response.data;
          this.sessionID = response.data.id;
        });
    },
    getPrice(d) {
      if (this.isMonthly) {
        return d.monthly;
      } else {
        return d.annual;
      }
    },
    changePlan() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    getAccountData() {
      let url =
        process.env.VUE_APP_BASE_API_URL +
        "auth/" +
        this.$store.getters.getUserGUID +
        "/account";

      this.axios
        .get(url, { withCredentials: true })
        .then(response => {
          this.accountData = response.data;
          this.editName = response.data.loginName + "test";
          this.editEmail = response.data.email;
        })
        .then(() => {
          this.statsCards[1].value = this.daysTillRenewal(
            this.accountData.renewaldate
          );
          this.statsCards[1].footerText =
            "Renewal date: " + this.accountData.renewaldate.slice(0, 10);
        });
    },
    getLast7Days() {
      let url = process.env.VUE_APP_BASE_API_URL + "account/lastseven";

      this.axios.get(url, { withCredentials: true }).then(response => {
        this.statsCards[2].value = response.data.messages;
      });
    },
    priceToggle() {
      this.isMonthly = !this.isMonthly;
    },
    daysTillRenewal(subs) {
      if (subs == "") {
        return "";
      }

      let subDate = new Date(subs);

      let now = new Date();

      let days = Math.floor((subDate - now) / (1000 * 3600 * 24));

      let ret = "Today";

      if (days == 1) {
        ret = "Tomorrow";
      } else if (days > 1) {
        ret = days + " days";
      }

      return ret;
    },
    formattedDate(d) {
      let dt = new Date(d);

      return dt.toISOString().substring(0, 10);
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler

      this.handleSubmit();
    },
    validateEmail(e) {
      //eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
        return false;
      } else {
        return true;
      }
    },
    validateLength(s) {
      return s.length > 0;
    },

    handleSubmit() {
      // Exit when the form isn't valid

      let inputsValid = false;

      this.emailState = !this.validateEmail(this.editEmail);

      this.nameState = this.validateLength(this.editName);

      inputsValid = this.emailState || this.nameState;

      if (!inputsValid) {
        return;
      }

      this.updateAccountData();

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    updateAccountData() {
      let url =
        process.env.VUE_APP_BASE_API_URL +
        "auth/" +
        this.$store.getters.getUserGUID +
        "/account/update";

      let self = this;

      this.axios
        .post(
          url,
          {
            email: this.editEmail,
            loginName: this.editName
          },
          { withCredentials: true }
        ) //eslint-disable-next-line
        .then(function(resp) {
          self.getAccountData();
        });
    }
  },
  mounted() {},
  computed: {}
};
</script>

<style scoped></style>
