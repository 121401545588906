<template>
  <div>
    <h1>Logged in</h1>
    <p>response from server: {{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "NotLoggedIN",
  components: {},
  props: {},
  mounted() {
    this.axios
      .get("http://localhost:3000")
      .then(response => (this.text = response));
  },
  data() {
    return { text: "" };
  },

  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
