import { render, staticRenderFns } from "./chatWindow.vue?vue&type=template&id=660bb5dd&scoped=true"
import script from "./chatWindow.vue?vue&type=script&lang=js"
export * from "./chatWindow.vue?vue&type=script&lang=js"
import style0 from "./chatWindow.vue?vue&type=style&index=0&id=660bb5dd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660bb5dd",
  null
  
)

export default component.exports