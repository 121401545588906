<template>
  <div>
    <div
      style="color: grey; "
      v-bind:class="inbound ? 'headerIn' : 'headerOut'"
    >
      <i
        v-bind:class="
          inbound ? 'fa-solid fa-arrow-left' : 'fa-solid fa-arrow-right'
        "
      ></i>
      <strong>{{ sender }}</strong>
      {{ readableLocaleDatetime(this.dateTime) }}
      <i
        class="fa-solid fa-gears color-blue-grey-500"
        v-show="this.jobID > 0"
        v-b-tooltip.hover
        title="SMS sent by automation"
      ></i>
    </div>
    <div
      v-bind:class="inbound ? 'messageIn' : 'messageOut'"
      style="box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;;"
    >
      <div
        v-html="convertToUrl(this.body)"
        style="text-align:left; white-space:break-spaces;"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "messageBubble",
  props: {
    sender: String,
    body: String,
    dateTime: String,
    sendMode: String,
    contact: String,
    inbound: Boolean,
    jobID: Number
  },
  methods: {
    convertToUrl(str) {
      //find a url:
      let hlink = /(\b(((https?|ftp|file|):\/\/)|www[.])[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi; //eslint-disable-line
      let temp = str.replace(hlink, '<a href="$1" target="_blank">$1</a>');

      return temp;
    },
    readableLocaleDatetime(dt) {
      let d = new Date(dt);
      return d.toString().substring(4, 21);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.messages {
  padding: 1rem;

  flex-shrink: 2;
  overflow-y: auto;

  box-shadow: inset 0 2rem 2rem -2rem rgba(black, 0.05),
    inset 0 -2rem 2rem -2rem rgba(black, 0.05);
}
.messageOut {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  margin: 0rem auto 1rem 1rem;
  background: #93939340;
  border-radius: 1.125rem 1.125rem 1.125rem 0;
  min-height: 2.25rem;
  width: fit-content;
  max-width: 66%;
  font-size: 14px;

  max-width: 250px;
  word-break: break-word;
  box-shadow: 0 0 2rem rgba(black, 0.075), 0rem 1rem 1rem -1rem rgba(black, 0.1);
}

.messageIn {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  margin: 0rem 1rem 1rem auto;
  background: #93939340;
  border-radius: 1.125rem 1.125rem 0 1.125rem;
  min-height: 2.25rem;
  width: fit-content;
  max-width: 66%;
  color: white;
  background: #333;
  font-size: 14px;

  max-width: 250px;
  word-break: break-word;
  box-shadow: 0 0 2rem rgba(black, 0.075), 0rem 1rem 1rem -1rem rgba(black, 0.1);
}

.headerIn {
  text-align: right;
  font-size: 13px;
  margin-right: 16px;
}

.headerOut {
  text-align: left;
  font-size: 13px;
  margin-left: 16px;
}
</style>
