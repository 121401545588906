<template>
  <div>
    <br />
    <b-overlay :show="spinner" rounded="sm">
      <div
        style="max-width: 80%; margin-bottom: 40px; margin-top:40px;"
        class="mx-auto"
      >
        <div style="max-width: 500px; text-align: center; margin: auto;">
          <b-form @submit.prevent="loginAuth">
            <h1 class="mb-5">Login</h1>
            <b-form-group id="input-group-1" label-for="input-1"
              >Email
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                required
                placeholder="Enter email"
                class="mb-4"
              ></b-form-input>
              Password
              <b-form-input
                id="input-3"
                v-model="form.password"
                type="password"
                required
                placeholder="password"
                class="mb-4"
              ></b-form-input>
            </b-form-group>
            <br />
            <b-button
              type="submit"
              variant="primary"
              class="mx-4 px-4 py-3 my-3"
              ><i class="fa-solid fa-arrow-right-to-bracket mx-2"></i>
              <span class="mx-1">Login</span></b-button
            >
            <hr />
          </b-form>
        </div></div
    ></b-overlay>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  props: {},
  data() {
    return {
      text: "",
      signUpText: "",
      validemail: "",
      form: {},
      spinner: false
    };
  },

  methods: {
    isEmailValid: function(email) {
      console.log(email);

      var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email) ? true : false;
    },

    loginAuth() {
      //need to encrypt password

      this.spinner = false;

      this.axios

        .post(
          process.env.VUE_APP_BASE_API_URL + "login",
          {
            username: this.form.email,
            password: this.form.password
          },
          { withCredentials: true }
        )
        .then(response => {
          this.spinner = false;
          this.text = response.data.auth;
          this.$store.commit("setAuth", {
            auth: response.data.auth,
            user: response.data.user,
            room: response.data.room,
            credits: response.data.credits
          });
          this.$socket.client.emit("JOIN", response.data.room);
          this.$router.push("panel/account");
        });
    }
  },
  mounted() {
    console.log("2:ENV:" + process.env.VUE_APP_BASE_API_URL);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
