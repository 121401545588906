<template>
  <div>
    <br />
    <div
      v-if="this.requestPasswordChange"
      style="max-width: 500px; text-align: center; margin: auto;"
    >
      Type in Email
      <b-form @submit.prevent="resetRequest">
        <h1>Email</h1>
        <label>Email</label>
        <b-form-group
          id="input-group-1"
          label="Email address:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            required
            placeholder="Enter email"
          ></b-form-input>
        </b-form-group>
        <br />
        <b-button type="submit" variant="primary"
          >email password reset</b-button
        >
        <hr />
      </b-form>
      text
      {{ text }}
    </div>
    <div v-else style="max-width: 500px; text-align: center; margin: auto;">
      <div v-if="spinner">
        <div class="text-center">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
      </div>
      <div v-else>
        <b-form @submit.prevent="updatePassword">
          <h1>Password</h1>
          <label>Password 1</label>
          <b-form-group id="input-group-1" label="Password" label-for="input-2">
            <b-form-input
              id="password-2"
              v-model="form.password1"
              type="password"
              required
              placeholder="Type Password"
            ></b-form-input>
            <label>Password2</label>
            <b-form-input
              id="password-2"
              v-model="form.password2"
              type="password"
              required
              placeholder="Retype Password"
            ></b-form-input>
          </b-form-group>
          <br />
          <b-button type="submit" variant="primary">reset password</b-button>
          <hr />
        </b-form>
        <div v-if="showUpdateAlerts">
          <div v-if="passwordConfirmationRule">
            <b-alert show variant="success"
              >Success Alert <i class="fa fa-check" aria-hidden="true"></i>
            </b-alert>
          </div>
          <div v-else>
            <b-alert show variant="warning">Warning Alert</b-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reset",
  components: {},
  props: {},
  mounted() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
      this.requestPasswordChange = false;
      this.checkCode();
    }
  },
  data() {
    return {
      validemail: "",
      text: "",
      form: {},
      requestPasswordChange: true,
      code: "",
      codeValid: "",
      spinner: true
    };
  },
  computed: {
    passwordConfirmationRule() {
      if (this.form.password1 != this.form.password2) {
        return false;
      } else {
        return true;
      }
    },
    showUpdateAlerts() {
      if (!this.form.password1) {
        return false;
      } else {
        return true;
      }
    }
  },

  methods: {
    isEmailValid: function(email) {
      console.log(email);

      var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email) ? true : false;
    },

    resetRequest() {
      //need to encrypt password

      this.axios

        .post(
          process.env.VUE_APP_BASE_API_URL + "resetpassword",
          {
            email: this.form.email
          },
          { withCredentials: true }
        )
        .then(response => {
          this.text = response;
        });
    },

    checkCode() {
      //need to encrypt password

      this.axios

        .post(
          process.env.VUE_APP_BASE_API_URL + "resetpasswordcode",
          {
            code: this.code
          },
          { withCredentials: true }
        )
        .then(response => {
          this.codeValid = response;
          this.spinner = false;
        });
    },

    updatePassword() {
      //need to encrypt password

      this.axios

        .post(
          process.env.VUE_APP_BASE_API_URL + "updatepassword",
          {
            code: this.code,
            password1: this.form.password1,
            password2: this.form.password2
          },
          { withCredentials: true }
        )
        .then(response => {
          this.codeValid = response;
          this.spinner = false;
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
