<template>
  <div>
    <b-overlay :show="spinner" rounded="sm">
      <br />
      <div style="max-width: 80%;" class="mx-auto my-4">
        <div style="max-width: 500px; text-align: center; margin: auto;">
          <b-form @submit.prevent="signUp">
            <h1 class="mb-5">Sign up!</h1>
            <b-form-group
              id="input-group-1"
              label-for="input-1"
              description="We'll never share your email with anyone."
              >Your Account Email
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                required
                placeholder="Your email"
                class="mb-4"
              ></b-form-input>
              <div v-show="showEmailInUseMessage" class="color-yellow-800 mb-3">
                <i class="fa-solid fa-circle-exclamation"></i
                ><span class="mx-2 ">{{ signUpText }}</span>
              </div>
              Password
              <b-form-input
                id="input-3"
                v-model="form.password"
                type="password"
                required
                placeholder="You password"
                class="mb-4"
              ></b-form-input>
            </b-form-group>
            <br />
            <b-button
              type="submit"
              variant="primary"
              class="mx-4 px-4 py-3 my-3"
              ><i class="fa-solid fa-user-plus mx-2"></i>Create
              Account</b-button
            >
            <hr />
          </b-form>
        </div></div
    ></b-overlay>
  </div>
</template>

<script>
export default {
  name: "Auth",
  components: {},
  props: {},
  mounted() {},
  data() {
    return {
      text: "",
      signUpText: "",
      validemail: "",
      form: {},
      showEmailInUseMessage: false,
      spinner: false
    };
  },

  methods: {
    signUp() {
      this.showEmailInUseMessage = false;
      this.spinner = true;

      this.axios
        .post(
          process.env.VUE_APP_BASE_API_URL + "signUp",
          {
            username: this.form.email,
            password: this.form.password
          },
          { withCredentials: true }
        )
        .then(response => {
          //handle user exists.

          let resp = response.data;

          if (resp.signup) {
            this.spinner = false;
            this.$store.commit("setAuth", {
              auth: response.data.auth,
              user: response.data.user,
              room: response.data.room,
              credits: response.data.credits
            });
            this.$socket.client.emit("JOIN", response.data.room);
            this.$router.push("panel/account");
          } else {
            this.spinner = false;
            this.showEmailInUseMessage = true;
            this.signUpText = response.data.info;
          }
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
