<template>
  <div class="d-flex" style="min-width: 100%;">
    <div
      style="padding:30px; min-width: 100%;"
      class="color-blue-grey-600 bg-grey-50"
    >
      <div
        style="font-size:32px; max-width:1000px;"
        class="my-3 color-blue-grey-700"
      >
        <div class="text-left">
          <i class="far fa-address-book color-light-blue-700"></i> Contacts
        </div>

        <h4 class="my-3 text-muted">
          New contacts created in Xero will automatically show up here
        </h4>
      </div>
      <div style="max-width:1000px;">
        <b-form-group
          label-for="filter-input"
          v-model="filter"
          type="search"
          placeholder="Type to Filter List"
          class="mt-3 mx-auto"
          style="max-width:400px;"
        >
          <b-input-group size="sm" class="mx-auto my-auto">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Filter List"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- <b-button @click="refreshList" class="mb-3 mx-auto" variant="outline-dark"
        ><b-icon icon="arrow-repeat"></b-icon> Refresh Contact List</b-button
      > -->

        <br />
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0 color-blue-grey-700"
          style="max-width: 1000px;"
        ></b-pagination>

        <b-table
          ref="contacts"
          :items="contacts"
          :fields="fields"
          :filter="filter"
          :current-page="currentPage"
          :per-page="perPage"
          @filtered="onFiltered"
          hover
          style="box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1); background:white; max-width: 1200px; font-size:13px; text-align:left"
        >
          <template #cell(Short)="contacts">
            <b-avatar
              size="3em"
              :text="getInitials(contacts.item.contactBusinessName)"
              :variant="getVariant(contacts.item.contactBusinessName)"
            ></b-avatar>
          </template>

          <template #cell(source)="contacts">
            <div
              v-if="contacts.item.source == 'user created'"
              class="my-auto mx-auto"
            >
              <b-icon
                icon="cloud-upload"
                font-scale="2"
                :title="contacts.item.source"
              >
              </b-icon>
            </div>
            <div v-else>
              <b-img
                src="https://omnimodaStorage.b-cdn.net/Xero%20-%20Logo%20-%20Blue%20-%20cropped.png"
                alt="no image"
                style="height:40px;"
                class="mx-auto my-auto"
              />
            </div>
          </template>
          <template #cell(contactname)="contacts">
            <span
              v-html="highlight(contacts.item.contactFirstName)"
              class="color-grey-600"
            ></span
            >&nbsp;
            <span
              v-html="highlight(contacts.item.contactLastName)"
              class="color-grey-600"
            ></span>
          </template>
          <template #cell(Conv)="contacts">
            <!-- <router-link
            :to="{
              name: 'messages',
              query: {
                contactID: contacts.item.contactID,
                contactName: contacts.item.contactBusinessName
              }
            }"
          > -->
            <b-icon
              icon="chat-left"
              font-scale="2"
              @click="
                setChatWindowContactID(
                  contacts.item.sourceContactID,
                  contacts.item.contactBusinessName,
                  contacts.item.cleanPhoneNumber,
                  contacts.item.contactFirstName,
                  contacts.organisationName
                )
              "
            ></b-icon>
            <!-- </router-link> -->
          </template>
          <template #cell(canSMS)="contacts">
            <div
              v-show="
                contactHasAtleast1ValidNumber(contacts.item.sourceContactID) > 0
              "
              class="color-green-700"
            >
              <i class="fa-solid fa-comment-sms"></i> Ready
            </div>
            <div
              v-show="
                contactHasAtleast1ValidNumber(contacts.item.sourceContactID) ==
                  0
              "
              class="color-orange-400"
              style="font-size:12px;"
            >
              <i class="fa-solid fa-comment-sms"></i> Not Ready
            </div>
          </template>
          <template #cell(phones)="contacts">
            <small>
              <div
                v-for="(ph, index) in filterPhonenumbers(
                  contacts.item.sourceContactID
                )"
                :key="index"
                class="d-flex mb-1"
              >
                <div class="d-flex" style="flex-shrink: 0;">
                  <span style="width:30px;" class="mr-1">{{
                    ph.PhoneType
                  }}</span>
                  <strong class="mx-3 color-blue-grey-700">{{
                    ph.PhoneNumber ? ph.PhoneNumber : "-"
                  }}</strong>
                </div>
                <div
                  v-show="validPhoneNumber(ph.PhoneNumber)"
                  class="bg-green-100 color-green-700 badge badge-pill"
                  v-b-tooltip.hover
                  title="Number has a valid format for AU & NZ"
                  style="padding:0.5em; font-size:100%; margin-top:-4px; margin-bottom:6px;"
                >
                  <i class="fa-solid fa-check"></i> Valid
                </div>
                <div
                  v-show="
                    !validPhoneNumber(ph.PhoneNumber) &&
                      contactHasAtleast1ValidNumber(
                        contacts.item.sourceContactID
                      ) == 0
                  "
                  class=""
                >
                  <b-button
                    style="font-size: 0.65rem; padding: 0.2rem; margin-top:-4px; margin-bottom:6px;"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    v-b-modal.modal
                    v-b-tooltip.hover
                    title="Click to update the number in Xero"
                    @click="
                      modalData.contactID = contacts.item.sourceContactID;
                      modalData.name = contacts.item.contactBusinessName;
                      modalData.ph = ph.PhoneNumber;
                      modalData.type = ph.PhoneType;
                      modalData.src = contacts.item.source;
                      modalData.file = contacts.item.Name;
                    "
                    >Update Details</b-button
                  >
                </div>
                <div v-show="validPhoneNumber(ph.PhoneNumber)" class="mx-1">
                  <b-button
                    v-show="!ph.validFlag"
                    style="font-size: 0.65rem; padding: 0.2rem; margin-top:-4px; margin-bottom:6px;"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    v-b-tooltip.hover
                    title="Click to check if the number is in use"
                    @click="
                      checkNumberIsActive(
                        ph.PhoneNumber,
                        ph.ContactID,
                        ph.PhoneType
                      )
                    "
                    >Check if active</b-button
                  >
                  <div
                    class="badge badge-pill bg-blue-100 color-blue-700 mx-1"
                    v-show="ph.validFlag"
                    v-b-tooltip.hover
                    title="This number is active"
                    style="padding:0.5em; font-size:100%; margin-top:-4px; margin-bottom:6px;"
                  >
                    <i class="fa-solid fa-tower-broadcast"></i> In Service
                  </div>
                </div>
              </div></small
            >
          </template>
          <template #cell(Cont)="contacts">
            <router-link
              v-b-tooltip.hover.topleft="'View contact details'"
              :to="{
                name: 'contact',
                query: {
                  contactID: contacts.item.contactID,
                  contactName: contacts.item.contactBusinessName
                }
              }"
            >
              <i class="fa-regular fa-address-card fa-xl"></i>
            </router-link>
          </template>
        </b-table>
        <div class="card m-5" v-show="this.totalRows == 0">
          <div class="d-flex">
            <i class="fa-solid fa-circle-exclamation mx-1 my-1"></i> No contacts
            - connect to a Xero file to import your contacts or add one using
            the button above
          </div>
        </div>
      </div>

      <chatWindow
        :contactID="this.chatWindowContact"
        :contactName="this.chatWindowContactName"
        :cleanPhoneNumber="this.cleanPhoneNumber"
        :contactFirstName="this.contactFirstName"
        :organisationName="this.organisationName"
        @close="this.closeWindow"
      />
    </div>
    <b-modal
      id="modal"
      ref="modal"
      size="md"
      centered
      title-class="H6"
      title="Update contact phone number"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      style="font-size:14px;"
      button-size="sm"
    >
      <small class="color-grey-700"
        ><p>
          Update the contact phone record for
          <strong class="color-blue-700">{{ modalData.name }}</strong>
        </p>

        <p class="text-muted">
          <i class="fa-solid fa-phone"></i> Current number:
          <strong class="color-blue-600">{{ modalData.ph }}</strong>
        </p>
        <p style="font-size:11px;" class="color-blue-grey-500">
          Omnimoda requires a mobile number to send an sms. Austalia and New
          Zealand numbers only
        </p>
        <p style="font-size:11px;" v-show="modalData.src == 'xero'">
          <span class="color-pink-400">Note:</span>
          this will update your
          <b-img
            src="https://omnimodaStorage.b-cdn.net/Xero%20-%20Logo%20-%20Blue%20-%20cropped.png"
            alt="no image"
            style="height:18px;"
            class="mx-auto"
          />
          Xero <strong>{{ modalData.file }}</strong> file.
        </p>
      </small>
      <b-form-input
        placeholder="Enter number"
        v-model="newNumber"
        :state="validPhoneNumber(newNumber)"
        id="input-live"
      />
      <b-form-invalid-feedback id="input-live-feedback">
        <span style="font-size:11px"
          >Provide the number in either 0XXX-XXX-XXX or 0XXXXXXXXX format</span
        >
      </b-form-invalid-feedback>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="success"
          v-show="validPhoneNumber(newNumber)"
          @click="
            ok();
            updateDetails();
          "
        >
          <i class="fa-regular fa-pen-to-square"></i> Update
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          <i class="fa-regular fa-circle-xmark"></i> Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import chatWindow from "./chatWindow.vue";
import { EventBus } from "../../eventBus";

export default {
  name: "contacts",
  components: { chatWindow },
  data() {
    return {
      newNumber: "",
      state: "",
      modalData: { contactID: "", name: "", ph: "", type: "", src: "" },
      chatWindowContact: "",
      chatWindowContactName: "",
      cleanPhoneNumber: "",
      contactFirstName: "",
      organisationName: "",

      imageSrc: {
        xero: "https://omnimodaStorage.b-cdn.net/Xero%20-%20Logo%20-%20Blue.png"
      },
      sortBy: "contactBusinessName",
      sortDesc: false,
      fields: [
        { key: "Short", label: "Avatar", class: "colToggle" },
        { key: "source", label: "Source", class: "colToggle" },
        { key: "contactBusinessName", label: "Business Name", sortable: true },
        { key: "CountryCode", label: "Country", class: "colToggle" },
        { key: "canSMS", label: "SMS ready" },
        { key: "phones", label: "Phone Numbers", class: "colToggle" }
      ],
      text: "",
      filter: null,
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      phonenumbers: []
    };
  },
  methods: {
    updateDetails() {
      let payload = { newph: this.newNumber, ...this.modalData };

      this.axios
        .post(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/contact/updatePhoneNumber",
          payload,
          {
            withCredentials: true
          }
        )
        .then(() => {
          //push into store
          this.getNumbers();
        });
    },
    contactHasAtleast1ValidNumber(contactID) {
      let canSMS = this.phonenumbers.filter(
        r => r.ContactID == contactID && this.validPhoneNumber(r.PhoneNumber)
      );

      return canSMS.length;
    },
    checkNumberIsActive(ph, cont, type) {
      let payload = { ph: ph, contactID: cont, type: type };

      this.axios
        .post(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/validateMobile",
          payload,
          {
            withCredentials: true
          }
        )
        .then(() => {
          //push into store

          this.getNumbers();
        });
    },
    validPhoneNumber(Number) {
      if (!Number) {
        return false;
      }

      Number = Number.replaceAll("-", "");

      if (Number.length < 9) {
        return false;
      }

      let numberFortesting = Number.slice(-9);

      let australianPattern = /^4[0-8]\d{7}$/g;
      let newZealandPattern = /^2[0-8]\d{7}$/g;

      return (
        australianPattern.test(numberFortesting) ||
        newZealandPattern.test(numberFortesting)
      );

      //return true
    },
    closeWindow() {
      this.chatWindowContact = "";
    },
    emitTest() {
      EventBus.$emit("scrollToBottom", "");
    },
    filterPhonenumbers(contactID) {
      return this.phonenumbers.filter(r => r.ContactID == contactID);
    },
    refreshList() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/contacts",
          {
            withCredentials: true
          }
        )
        .then(response => {
          //push into store

          let r = response.data;
          this.$store.commit("storeContacts", {
            r
          });
          this.totalRows = this.contacts.length;
        });
    },
    getNumbers() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/allPhoneNumbers",
          {
            withCredentials: true
          }
        )
        .then(response => {
          //push into store

          this.phonenumbers = response.data;
        });
    },
    highlight(n) {
      if (!this.filter) {
        return n;
      }
      return n.replace(new RegExp(this.filter, "i"), match => {
        return (
          '<nobr><span style="background-color: yellow; white-space:nowrap; display:inline;">' +
          match +
          "</span></nobr>"
        );
      });
    },
    getInitials(str) {
      var matches = str.match(/\b(\w)/g);
      return matches.join("").substring(0, 3);
    },
    getVariant(str) {
      let initials = this.getInitials(str);

      let i = initials.charCodeAt(0);

      let i2 = initials.charCodeAt(1) ? initials.charCodeAt(1) : 0;

      let i3 = initials.charCodeAt(2) ? initials.charCodeAt(2) : 0;

      var options = [
        "secondary",
        "primary",
        "dark",
        "light",
        "success",
        "danger",
        "warning",
        "info"
      ];

      return options[(i + i2 + i3) % 8];
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setChatWindowContactID(id, name) {
      this.chatWindowContactName = name;
      this.chatWindowContact = id;
    }
  },
  computed: {
    contacts() {
      return this.$store.getters.getContacts;
    }
  },
  mounted() {
    this.refreshList();
    this.getNumbers();
    this.totalRows = this.contacts.length;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.colToggle {
  display: table-cell;
}

.respMar {
  margin: 3em;
}

.contactButton {
  margin-left: auto;
  margin-right: 48px;
  padding-top: 4px;
}

@media only screen and (max-width: 600px) {
  .colToggle {
    display: none;
  }
  .respMar {
    margin: 0.5em;
  }
  .contactButton {
    margin: auto;
  }
}
</style>
